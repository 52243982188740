import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Blogs.scss';
import { BlogContext } from '../../../context/BlogContext';

const Blogs = ({ title, isPage, isHome }) => {
  const { blogs, loading } = useContext(BlogContext);

  if (loading) {
    return <p>Loading...</p>;
  }

  const displayedBlogs = blogs.slice(0, 6);

  const extractImageUrl = (blog) => {
    const content = blog.content;
    const imgMatch = content.match(/<img[^>]+src="([^">]+)"/);
    return imgMatch ? imgMatch[1] : null;
  };

  const estimateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const text = content.replace(/<[^>]+>/g, '');
    const wordCount = text.split(/\s+/).length;
    const minutes = Math.ceil(wordCount / wordsPerMinute);
    return minutes;
  };

  return (
    <div className="our-blogs-container" style={isPage ? { gap: 0 } : {}}>
      <div
        className="header"
        style={isPage ? { marginTop: '4rem', marginBottom: 0 } : {}}
      >
        <p>{title || 'Blogs & Articles'}</p>
      </div>
      <div className="blogs-list">
        {isHome
          ? displayedBlogs.map((blog, index) => (
              <div className="blogs-card" key={index}>
                <img
                  src={extractImageUrl(blog)}
                  alt={blog.title}
                  className="blogs-card-image"
                />
                <div className="user">
                  <div className="blogs-info">
                    <p>{estimateReadingTime(blog.content)} min read</p>
                    <h3>{blog.title}</h3>
                    <a href={blog.link}>See more</a>
                  </div>
                </div>
              </div>
            ))
          : blogs.map((blog, index) => (
              <div className="blogs-card" key={index}>
                <img
                  src={extractImageUrl(blog)}
                  alt={blog.title}
                  className="blogs-card-image"
                />
                <div className="user">
                  <div className="blogs-info">
                    <p>{estimateReadingTime(blog.content)} min read</p>
                    <h3>{blog.title}</h3>
                    <a href={blog.link}>See more</a>
                  </div>
                </div>
              </div>
            ))}
      </div>
      {isHome && (
        <div className="see-more-container">
          <Link to="/blog" className="see-more-button">
            See More
          </Link>
        </div>
      )}
    </div>
  );
};

export default Blogs;
