import axios from 'axios';

export const fetchBlogPosts = async () => {
    try {
        const response = await axios.get(
          process.env.REACT_APP_MEDIUM_URL ||
          'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@jreddycr'
        );
        return response.data.items;
    } catch (error) {
        console.error('Error fetching blog posts:', error);
        return [];
    }
};
