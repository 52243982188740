import React from 'react';
import { motion } from 'framer-motion';
import './WhyChooseUs.scss';
import dash from '../../../assets/boxes/dash.png';

const WhyChooseUs = ({ data }) => {
  return (
    <div className="why-choose-use-container">
      <div className="header">
        <p>{data.title}</p>
      </div>
      <div className="choose-list">
        {data.additionalInfo.map((process, index) => (
          <motion.div
            className="choose-wrapper"
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: index * 0.5, ease: 'easeInOut' }}
          >
            <div className="logo">
              <img src={process.iconadditional?.asset?.url} alt="logo" />
            </div>
            <div className="choose-card">
              <div className="choose-info">
                <div className="flex">
                  <span><img src={dash} alt="dash" /></span>
                  <h3><ColoredText text={process.title} /></h3>
                </div>
                <p>{process.subTitle}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};
const ColoredText = ({ text }) => {
  const wordsToColor = ['Comprehensive', 'Partnership', 'Tailor-Made', 'Commitment'];
  const textParts = text.split(new RegExp(`(${wordsToColor.join('|')})`, 'g'));

  return (
    <>
      {textParts.map((part, index) => (
        <span key={index} style={{ color: wordsToColor.includes(part) ? '#373B9C' : 'inherit' }}>
          {part}
        </span>
      ))}
    </>
  );
};

export default WhyChooseUs;
