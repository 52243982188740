import React, { useState, useEffect } from 'react';
import OurServices from '../components/services/ourServices/Services'
import OurClients from '../components/Landing/ourClients/OurClients'
import AnimatedSection from '../components/Animation/AnimatedSection'
import { servicesQuery } from '../constants/queries';
import createClient from '@sanity/client';
import createImageUrlBuilder from '@sanity/image-url';
import OurClient2 from '../components/Landing/ourClients/OurClient2';





export const client = createClient({
    projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: process.env.REACT_APP_SANITY_TOKEN,
  });
  
  const builder = createImageUrlBuilder(client);
  
  export const urlFor = (source) => {
    if (!source || typeof source !== 'object') {
      console.error('Invalid source object passed to urlFor');
      return '';
    }
  
    if (source._type !== 'image' || !source.asset || !source.asset._ref) {
      console.error('Invalid image source data');
      return ''; 
    }
  
    return builder.image(source.asset._ref);
  };



export default function Services() {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      client
        .fetch(servicesQuery())
        .then((data) => {
          setData(data[0]); 
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }, []);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (!data) {
      return <div>No data found</div>;
    }


    return (
        <div>
            <AnimatedSection>
            <OurServices data={data} />
            </AnimatedSection>
            
            <AnimatedSection>
            <OurClient2 isAboutUs={true} data={data}/>
            </AnimatedSection>
  
        </div>
    )
}
