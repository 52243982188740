import React, { useContext } from 'react';
import { Navbar } from './components';
import './App.scss';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Layout from './components/Layout';
import Landings from './pages/Landing';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import OurProduct from './pages/OurProduct';
import Services from './pages/Services';
import Footer from './container/Footer/Footer';
import Blog from './components/Landing/Blogs/Blogs';
import { BlogContext } from './context/BlogContext';
const pageVariants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 1.2,
  },
};

const pageTransition = {
  type: 'tween',
  ease: 'easeInOut',
  duration: 0.4,
};

const AnimatedRoutes = () => {
  const { blogs } = useContext(BlogContext);
  
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <motion.div
                key="landing"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <Landings />
              </motion.div>
            }
          />
          <Route
            path="/about-us"
            element={
              <motion.div
                key="about"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <AboutUs />
              </motion.div>
            }
          />
          <Route
            path="/contact"
            element={
              <motion.div
                key="contact"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <ContactUs />
              </motion.div>
            }
          />
          <Route
            path="/product"
            element={
              <motion.div
                key="product"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <OurProduct />
              </motion.div>
            }
          />
          <Route
            path="/services"
            element={
              <motion.div
                key="services"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <Services />
              </motion.div>
            }
          />
          <Route
            path="/blog"
            element={
              <motion.div
                key="services"
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
                className="height"
              >
                <Blog data={blogs} isPage={true}/>
              </motion.div>
            }
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <AnimatedRoutes />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
