import React from 'react';
import './Form.scss';

const Form = ({ data }) => {
  return (
    <div className="form-container">
      <form className="form">
        <div className="form-group">
          <label htmlFor="name">{data.form.formName}</label>
          <input type="text" id="name" name="name" />
        </div>
        <div className="form-group">
          <label htmlFor="email">{data.form.formEmail}</label>
          <input type="email" id="email" name="email" />
        </div>
        <div className="form-group">
          <label htmlFor="phone">{data.form.phonenumber}</label>
          <input type="text" id="phone" name="phone" />
        </div>
        <div className="form-group">
          <label htmlFor="message">{data.form.message}</label>
          <textarea id="message" name="message" rows="4"></textarea>
        </div>
        <div className="form-group">
        {/* onClick={handleSubmit} */}
          <div className="submit-button" >{data.form.submit}</div>
        </div>      </form>
    </div>
  );
};

export default Form;