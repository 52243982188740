export const aboutQuery = () => {
  return `*[_type == "about"]{
      title,
      subtitle,
      description,
      keyPeople[]{
        personName,
        imgUrl{
          asset->{
            _id,
            url
          }
        }
      },
      imageGallery[]{
        asset->{
          _id,
          url
        }
      },
      additionalInfo[]{
        title,
         iconadditional{
        asset->{
          _id,
          url
        }
      },
        subTitle,
        colorWord
      },
      boxes[]{
        title,
        subTitle,
        newLine,
        color,
        image{
          asset->{
            _id,
            url
          }
        },
        number,
        content
      },  
      ourClient[]{
      name,
      feedback,
      position,
      image{
        asset->{
          _id,
          url
        }
      }
    },
     bluBox{
        title,
        subtitle,
        buttonName,
        href
      }
    }`;
};

export const contactQuery = () => {
  return `*[_type == "contact"]{
    name,
    email,
    emailaddress,
    message,
    header,
    subheader,
    header2,
    subheader2,
    phone,
    phonenum,
    office,
    officeloc,
    social,
    form {
      formName,
      formEmail,
      phonenumber,
      message,
      submit
    },
     imageGallery[]{
      asset->{
        _id,
        url
      }
    },
  }`;
};

export const productsQuery = () => {
  return `*[_type == "ProductsContent"]{
    title,
    uppertitle,
    blueboxtitle,
    blueboxdescription,
    blueboxbutton,
    

     products[]{
        title,
        subTitle,
        innerdescription,
     
      image{
        asset->{
          _id,
          url
        }
      }
         }, 




 
     productsgallery[]{
      asset->{
        _id,
        url
      }
    },
  }`;
};

export const servicesQuery = () => {
  return `*[_type == "services"]{
    title,
     services_section[]{
        service_subtitle,
        service_description,
     
      service_icon{
        asset->{
          _id,
          url
        }
      },
          service_image{
        asset->{
          _id,
          url
        }
      }
         }, 
           ourClient[]{
          name,
          feedback,
                    position,
        image{
          asset->{
            _id,
            url
          }
        }
           }, 
   client_saying_title,
   blueboxtitle,
   blueboxdes,
   blueboxbtn,
  }`;
};

export const landingQuery = () => {
  return `*[_type == "landing"]{

    titleblue,
    titlewhite,
    subtitle,


 blogs_section[]{
        blogtitle,
        blogtime,
     
      blogimage{
        asset->{
          _id,
          url
        }
      }
         }, 
          product_section[]{
        product_title,
        product_subtitle,
     
      product_image{
        asset->{
          _id,
          url
        }
      }
         }, 
               service_section[]{
        card_title,
        card_text,
     
      card_icon{
        asset->{
          _id,
          url
        }
      }
         }, 


         ourClient[]{
          name,
          feedback,
                    position,
        image{
          asset->{
            _id,
            url
          }
        }
           }, 

            header_images_section[]{
      
        header_images{
          asset->{
            _id,
            url
          }
        }
           }, 


           process_section[]{
          processtitle,
          processtext,
           }, 






    whoweare,
    whowearetxt,
    workbtn,
    sectioncategory,
    sectiontitle,
    clients3name,


    processtitle1,
    processtitle2,
    blogstitle,

    clientsfeedback,
   
   blueboxtitle,
   blueboxdes,
   blueboxbtn,

    uppertitle,
   
  


       

  }`;
};
