import React, { useState } from 'react';
import './OurClients.scss';
import arrowRight from '../../../assets/ourClients/arrowRight.png';
import arrowLeft from '../../../assets/ourClients/arrowLeft.png';
import userProfile from '../../../assets/ourClients/user1.png';
import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom'; 



const OurClient2 = ({isAboutUs, data, bluBox, isApi }) => {
    const navigate = useNavigate(); 




    const handleWorkWithUs = () => {
        navigate('/contact')
    }


    const [currentIndex, setCurrentIndex] = useState(0);
    const clientCards = data.ourClient;

    const nextSlide = () => {
        const nextIndex = currentIndex === clientCards.length - 1 ? 0 : currentIndex + 1;
        setCurrentIndex(nextIndex);
    };

    const prevSlide = () => {
        const prevIndex = currentIndex === 0 ? clientCards.length - 1 : currentIndex - 1;
        setCurrentIndex(prevIndex);
    };


    return (
        <div className="our-clients-container">
        <div className="header">
            {isAboutUs ? (
                <p>What our clients are <br /> saying</p>
            ) : (
                <p>What <span>our clients</span> are <br /> saying</p>
            )}
        </div>
        <div className="client-list">
            {clientCards.map((client, index) => (
                <motion.div
                    key={index}
                    className={`client-card ${index === currentIndex ? 'active' : ''}`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <div className='user'>
                        <img src={client.image.asset.url} alt={client.name} />
                        <div className="client-info">
                            <h3>{client.name}</h3>
                            <p>{client.position}</p>
                        </div>
                    </div>
                    <div className='arrow'>
                        <p> {client.feedback}</p>
                        <div className="carousel-buttons">
                            <button className="prev" onClick={prevSlide}><img src={arrowLeft} alt="Previous" /></button>
                            <button className="next" onClick={nextSlide}><img src={arrowRight} alt="Next" /></button>
                        </div>
                    </div>
                </motion.div>
            ))}
        </div>
        <div className="red-box">
            <h3>Achieve Digital Transformation Seamlessly</h3>
            <div className='desc'>
                <p>workAffix integrates fully into your team to solve IT challenges effectively. Let us navigate the complexities of IT for you while you focus on what you do best – running your business. </p>
                <button onClick={handleWorkWithUs} >Work with us.</button>
            </div>
        </div>
    </div>
);
}

export default OurClient2;
