import React, { useState, useEffect } from 'react';
import createClient from '@sanity/client';
import { contactQuery } from '../constants/queries';
import InfoBox from '../components/contact/FirstSection/InfoBox';
import Form from '../components/contact/FirstSection/Form';
import InfoContactSection from '../components/contact/InfoContactSection/InfoContactSection';
import './ContactUs.scss';
import ImageSection from '../components/contact/Image/ImageSection';
import { motion } from 'framer-motion'; 

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
});

const ContactUs = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client
      .fetch(contactQuery())
      .then((data) => {
        setData(data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  return (
    <div className="main-containers">

<div className="info-form-container">
        <InfoBox data={data} />
        <Form data={data} />
      </div>
      <div className="info-image-container">
        <motion.div
          initial={{ opacity: 0, x: '100vw' }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: 'tween', duration: 0.5 }} 
        >
          <ImageSection />
          <InfoContactSection data={data} />
        </motion.div>
      </div>
    
    </div>
  );
};

export default ContactUs;