import React, { useEffect, useRef } from 'react';
import './style.scss';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import button from '../../../assets/ourProducts/button1.png';

const OurProducts = ({ data }) => {
    const productRefs = useRef([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const handleScroll = () => {
            productRefs.current.forEach((ref, index) => {
                const top = ref.offsetTop;
                const height = ref.clientHeight;
                const scroll = window.scrollY;
                const windowHeight = window.innerHeight;

                const translateY = scroll > top - windowHeight + height * 0.5 ? 0 : -50;
                const opacity = scroll > top - windowHeight + height * 0.5 ? 1 : 0;

                ref.style.transition = 'transform 0.3s ease, opacity 0.3s ease';
                ref.style.transform = `translateY(${translateY}px)`;
                ref.style.opacity = opacity;
            });
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const productCards = data.product_section;

    const handleButtonClick = () => {
        navigate('/product'); // Navigate to the "Our Products" page
    };

    return (
        <div className="our-products-container">
            <div className="header">
                <h3>Our Products</h3>
                <p>Here is the stuff we<br />have built</p>
            </div>
            <div className="product-list">
                {productCards.map((product, index) => (
                    <motion.div
                        key={index}
                        className="product-card"
                        ref={element => (productRefs.current[index] = element)}
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                        <img src={product.product_image.asset.url} alt={product.name} />
                        <div className="product-info">
                            <h3>{product.product_title}</h3>
                            <p>{product.product_subtitle}</p>
                            <button onClick={handleButtonClick}> {/* Add onClick handler */}
                                <img src={button} alt="button" />
                            </button>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default OurProducts;
