import React, { useState } from 'react';
import './Services.scss';
import { motion } from 'framer-motion';
import button from '../../../assets/ourProducts/buttonPur.png';
import ServiceModal from '../ServiceModal';
import { useInView } from 'react-intersection-observer';

export default function Services({ data }) {
  const [selectedService, setSelectedService] = useState(null);
  const service = data.services_section;

  const handleButtonClick = (service) => {
    setSelectedService(service);
  };

  const handleCloseModal = () => {
    setSelectedService(null);
  };

  return (
    <div className="container">
      <p>text</p>
      <div className="header">
        <p>{data.title}</p>
      </div>
      {service.map((service, index) => (
        <ServiceCard
          key={index}
          service={service}
          index={index}
          handleButtonClick={handleButtonClick}
        />
      ))}
      <ServiceModal
        service={selectedService}
        onClose={handleCloseModal}
        title={data.title}
      />
    </div>
  );
}

const ServiceCard = ({ service, index, handleButtonClick }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: '-100px 0px',
  });

  const serviceIconUrl = service.service_icon?.asset?.url;
  const serviceImageUrl = service.service_image?.asset?.url;
  const serviceDescription = service.service_description || 'No Description';

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className="service-card"
    >
      <div className="line-break"></div>
      <div className="flex">
        <div className="flex-col">
          {serviceIconUrl && (
            <img
              src={serviceIconUrl}
              alt={`Service Icon ${index}`}
              className="service-icon"
            />
          )}
          <p>
            {serviceDescription.length > 100
              ? `${serviceDescription.substring(0, 150)}...`
              : serviceDescription}
          </p>
          <button onClick={() => handleButtonClick(service)}>
            <img src={button} alt="Open Modal" />
          </button>
        </div>
        {serviceImageUrl && (
          <img
            src={serviceImageUrl}
            alt={`Service Image ${index}`}
            className="service-image"
            style={{
              maxWidth: '600px',
              height: 'auto',
              objectPosition: 'center',
              objectFit: 'cover',
            }}
          />
        )}
      </div>
    </motion.div>
  );
};
