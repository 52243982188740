import React from 'react';
import ReactDom from 'react-dom';
import { BlogProvider } from './context/BlogContext';
import App from './App';
import './index.css';

ReactDom.render(
    <BlogProvider>
        <App />
    </BlogProvider>
    ,
    document.getElementById('root'));