import React, { useState } from 'react';
import './OurProducts.scss';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ProductModal from './ProductModal';
import button from '../../assets/ourProducts/buttonPur.png';
import { useNavigate } from 'react-router-dom';

const OurProducts = ({ data }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const productCards = data.products;
  const navigate = useNavigate(); 

  const handleClick = () => {
    navigate('/contact');
  };

  const handleButtonClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="our-product-container">
      <div className="header">
        <p>{data.uppertitle}</p>
      </div>
      <div className="product-list">
        {productCards.map((product, index) => (
          <ProductCard
            key={index}
            product={product}
            index={index}
            handleButtonClick={handleButtonClick}
          />
        ))}
      </div>
      <ProductModal product={selectedProduct} onClose={handleCloseModal} data={data} />
      <div className="red-box">
        <h3>{data.blueboxtitle}</h3>
        <div className="desc">
          <p>{data.blueboxdescription}</p>
          <button onClick={handleClick}>{data.blueboxbutton}</button>
        </div>
      </div>
    </div>
  );
};

const ProductCard = ({ product, index, handleButtonClick }) => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    rootMargin: '-100px 0px',
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 20 }}
      transition={{ duration: 0.5, delay: index * 0.2 }}
      className="product-card"
    >
      <img src={product.image.asset.url} alt={product.title} />
      <div className="product-info">
        <h3>{product.title}</h3>
        <p>{product.subTitle}</p>
        <button onClick={() => handleButtonClick(product)}>
          <img src={button} alt="button" />
        </button>
        <h4>0{index + 1}</h4>
      </div>
    </motion.div>
  );
};

export default OurProducts;
