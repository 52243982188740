import React, { useState, useEffect, useRef } from 'react';
import './DeviderSection.scss';

export default function DeviderSection({data}) {
  const boxRef = useRef(null); 

  const [animatedNumbers, setAnimatedNumbers] = useState(Array(data.boxes.length).fill(0));

  useEffect(() => {
    const animationDuration = 2000;
    const numbersToAnimate = data.boxes.map(data => parseFloat(data.number));

    const animateNumbers = () => {
      const startTime = Date.now();
      const updateNumbers = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        if (elapsedTime >= animationDuration) {
          setAnimatedNumbers(numbersToAnimate);
        } else {
          const progress = elapsedTime / animationDuration;
          const currentNumbers = numbersToAnimate.map((num, index) =>
            Math.floor(num * progress)
          );
          setAnimatedNumbers(currentNumbers);
          requestAnimationFrame(updateNumbers);
        }
      };
      requestAnimationFrame(updateNumbers);
    };

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          animateNumbers();
          observer.disconnect(); 
        }
      });
    });

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current); 
      }
    };
  }, []);

  return (
    <div className='boxes-row' ref={boxRef}>
      {data.boxes.map((data, index) => (
        <div className='box' key={index} style={{ backgroundColor: data.color }}>
          <img src={data.image.asset.url} alt={data.content} />
          <p>{animatedNumbers[index]}{typeof data.number === 'string' && data.number.includes('%') ? '%' : ''}</p>
          <div className='toghter'>
            <h2>{data.title}</h2>
            <h2>{data?.newLine}</h2>
          </div>
        </div>
      ))}
    </div>
  );
}
