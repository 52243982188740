// image.js
import email from '../assets/email.png';
import salesforce from '../assets/salesforce.png';
import mulesoft from '../assets/mulesoft.png';
import workaffix from '../assets/workaffix.png';
import development from '../assets/development.png';
import connectingIcon from '../assets/connectingIcon.png';
import database from '../assets/database.png';
import devops from '../assets/devops.png';
import mobile from '../assets/mobile.png';
import api from '../assets/api.png';
import cpp from '../assets/cpp.png';
import css from '../assets/css.png';
import figma from '../assets/figma.png';
import flutter from '../assets/flutter.png';
import git from '../assets/git.png';
import graphql from '../assets/graphql.png';
import html from '../assets/html.png';
import javascript from '../assets/javascript.png';
import mu5 from '../assets/mu5.png';
import node from '../assets/node.png';
import python from '../assets/python.png';
import react from '../assets/react.png';
import redux from '../assets/redux.png';
import sass from '../assets/sass.png';
import typescript from '../assets/typescript.png';
import vue from '../assets/vue.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import bolt from '../assets/bolt.png';
import nb from '../assets/nb.png';
import skype from '../assets/skype.png';
import spotify from '../assets/spotify.png';
import teamWork from '../assets/team_work.png';
import developerWork from '../assets/developer-work.png';
import data from '../assets/data.png';
import teamWork1 from '../assets/team1.png';
import service1 from '../assets/services/service1.png';
import service2 from '../assets/services/service2.png';
import service3 from '../assets/services/service3.png';
import service4 from '../assets/services/service4.png';
import serviceNail from '../assets/services/serviceNail.png';
import serviceNail2 from '../assets/services/serviceNail2.png';
import serviceNail3 from '../assets/services/serviceNail3.png';
import serviceNail4 from '../assets/services/serviceNail4.png';

import callIcon from '../assets/contact_Imgs/call.png';
import lnIcon from '../assets/contact_Imgs/linkedin.png';
import placeIcon from '../assets/contact_Imgs/place.png';
import mailIcon from '../assets/contact_Imgs/mail.png';
import xIcon from '../assets/contact_Imgs/twitter.png';


const images = {
  email,
  salesforce,
  mulesoft,
  workaffix,
  development,
  connectingIcon,
  devops,
  database,
  mobile,
  api,
  cpp,
  css,
  figma,
  flutter,
  git,
  graphql,
  html,
  javascript,
  mu5,
  node,
  python,
  react,
  redux,
  sass,
  typescript,
  vue,
  circle,
  logo,
  bolt,
  nb,
  skype,
  spotify,
  teamWork,
  developerWork,
  data,
  teamWork1,
  service4,
  service3,
  service2,
  service1,
  serviceNail4,
  serviceNail3,
  serviceNail2,
  serviceNail,
  callIcon,
  lnIcon,
  placeIcon,
  mailIcon,
  xIcon

};

export default images;
