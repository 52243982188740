import React, { useState, useEffect } from 'react';
import createClient from '@sanity/client';
import HeroSection from '../components/Landing/HeroSection/HeroSection';
import DescriptionSection from '../components/Landing/DescriptionSection/DescriptionSection';
import ServicesSection from '../components/Landing/ServicesSection/ServicesSection';
import OurProducts from '../components/Landing/ourProducts';
import OurClients3 from '../components/Landing/ourClients/OurClients';
import OurProcess from '../components/Landing/ourProcess/OurProcess';
import Blogs from '../components/Landing/Blogs/Blogs';
import createImageUrlBuilder from '@sanity/image-url';
import { landingQuery } from '../constants/queries';
import { fetchBlogPosts } from '../constants/api';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = createImageUrlBuilder(client);

export const urlFor = (source) => {
  if (!source || typeof source !== 'object') {
    console.error('Invalid source object passed to urlFor');
    return '';
  }

  if (source._type !== 'image' || !source.asset || !source.asset._ref) {
    console.error('Invalid image source data');
    return '';
  }

  return builder.image(source.asset._ref);
};

const Landing = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogPosts] = useState(null);

  useEffect(() => {
    client
      .fetch(landingQuery())
      .then((data) => {
        setData(data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      const posts = await fetchBlogPosts();
      setBlogPosts(posts);
      setLoading(false);
    };

    fetchPosts();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  return (
    <div className="app">
      <HeroSection data={data} />
      <DescriptionSection data={data} />
      <ServicesSection data={data} isApi={true} />
      <OurProducts data={data} isApi={true} />
      <OurClients3 data={data} />
      <OurProcess data={data} isApi={true} />
      {blogs && <Blogs data={blogs} title={data.blogstitle} isHome={true} />}
    </div>
  );
};

export default Landing;
