import React from "react";
import "./HeroSection.scss";

const HeroSection = ({ data }) => {
  const headerImagesSection = data.header_images_section || [];
  const image1 = headerImagesSection[0]?.header_images?.asset?.url || "";
  const image2 = headerImagesSection[1]?.header_images?.asset?.url || "";

  return (
    <section className="section">
      <div className="section__content">
        <h1>
          <span className="highlight">{data.titleblue}</span> {data.titlewhite}
        </h1>
        <p className="section__description">
          {data.subtitle}
        </p>
      </div>
      <div className="section__images">
        {image1 && (
          <img
            src={image1}
            alt="Team working"
            className="section__image"
          />
        )}
        <div className="square square-1"></div>
        {image2 && (
          <img
            src={image2}
            alt="Developer working"
            className="section__image"
          />
        )}
        <div className="square square-2"></div>
      </div>
    </section>
  );
};

export default HeroSection;
