import React from 'react';
import './ServiceModal.scss';

const ServiceModal = ({ title ,service, onClose }) => {

  if (!service) return null;


  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  // Destructure fields from service with default values

  

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        
        <img id='icon' src={service.service_icon?.asset?.url} ></img>
        <p>{service.service_description}</p>
          <div className="image-container">
            <img src={service.service_image?.asset?.url} alt={title} />
          </div>
    
      </div>
    </div>
  );
};

export default ServiceModal;