import React from "react";
import "./AdditionalSection.scss";

const AdditionalSection = ({data}) => {
  const qouteLeft = data.imageGallery[0].asset.url;
  const qouteRight = data.imageGallery[1].asset.url;
  return (
    <section className="additional-container">
      <div className="parent">
        <p>
          {data.title}
        </p>
        <div className="box-text">
          <div className="qoutLeft">
            <img src={qouteLeft} alt="Quote Left" />
          </div>
          <p>
            {data.subtitle}
          </p>
          <div className="qoutRight">
            <img src={qouteRight} alt="Quote Right" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdditionalSection;
