import React from 'react';
import './InfoBox.scss';

const InfoBox = ({ data }) => {
  return (
    <div className="info-box">
      <h2 className="info-box__title">{data.header}</h2>
      <p className="info-box__text">{data.subheader}</p>
  
    </div>
  );
};

export default InfoBox;