import createClient from '@sanity/client';
import createImageUrlBuilder from '@sanity/image-url';
import React, { useState, useEffect } from 'react';
import HeroSection from '../components/AboutUs/HeroSection/HeroSection';
import AdditionalSection from '../components/AboutUs/additionalSection/AdditionalSection';
import WhyChooseUs from '../components/AboutUs/whyChooseUs/WhyChooseUs';
import DeviderSection from '../components/AboutUs/deviderSection/DeviderSection';
import OurClients from '../components/Landing/ourClients/OurClients';
import './About.scss';
import AnimatedSection from '../components/Animation/AnimatedSection';
import { aboutQuery } from '../constants/queries';

export const client = createClient({
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
  dataset: 'production',
  apiVersion: '2022-02-01',
  useCdn: true,
  token: process.env.REACT_APP_SANITY_TOKEN,
});

const builder = createImageUrlBuilder(client);

export const urlFor = (source) => {
  if (!source || typeof source !== 'object') {
    console.error('Invalid source object passed to urlFor');
    return '';
  }

  if (source._type !== 'image' || !source.asset || !source.asset._ref) {
    console.error('Invalid image source data');
    return '';
  }

  return builder.image(source.asset._ref);
};

const AboutUs = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    client
      .fetch(aboutQuery())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>No data found</div>;
  }

  return (
    <div className="main-container">
      {data && (
        <>
          <HeroSection data={data[1]} />
          <AnimatedSection>
            <AdditionalSection data={data[3]} />
          </AnimatedSection>
          <AnimatedSection>
            <WhyChooseUs data={data[4]} />
          </AnimatedSection>
          <AnimatedSection>
            <DeviderSection data={data[5]} />
          </AnimatedSection>
          <AnimatedSection>
            <OurClients
              isAboutUs={true}
              data={data[2]}
              bluBox={data[0]?.bluBox}
              isApi={true}
            />
          </AnimatedSection>
        </>
      )}
    </div>
  );
};

export default AboutUs;
