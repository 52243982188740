import React, { useState } from 'react';
import './Navbar.scss';
import { images } from '../../constants';
import { HiMenuAlt4, HiX } from 'react-icons/hi';
import { motion } from 'framer-motion';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const [toggle, setToggle] = useState(false);
  const location = useLocation();

  const getPath = (path) => (location.pathname === path ? 'active' : '');

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {/* Wrap the logo with a Link component */}
        <Link to="/">
          <img src={images.logo} alt="logo" />
        </Link>
      </div>
      <ul className="app__navbar-links">
        <li className={`navbar-item ${getPath('/')}`}>
          <Link to="/">Home</Link>
        </li>
        <li className={`navbar-item ${getPath('/about-us')}`}>
          <Link to="/about-us">About Us</Link>
        </li>
        <li className={`navbar-item ${getPath('/services')}`}>
          <Link to="/services">Services</Link>
        </li>
        <li className={`navbar-item ${getPath('/product')}`}>
          <Link to="/product">Product</Link>
        </li>
        <li className={`navbar-item ${getPath('/contact')}`}>
          <Link to="/contact">Contact</Link>
        </li>
        <li className={`navbar-item ${getPath('/blog')}`}>
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />
        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: 'easeOut' }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul className="app__navbar-links">
              <li className={`navbar-item ${getPath('/')}`}>
                <Link to="/" onClick={() => setToggle(false)}>
                  Home
                </Link>
              </li>
              <li className={`navbar-item ${getPath('/about-us')}`}>
                <Link to="/about-us" onClick={() => setToggle(false)}>
                  About Us
                </Link>
              </li>
              <li className={`navbar-item ${getPath('/services')}`}>
                <Link to="/services" onClick={() => setToggle(false)}>
                  Services
                </Link>
              </li>
              <li className={`navbar-item ${getPath('/product')}`}>
                <Link to="/product" onClick={() => setToggle(false)}>
                  Product
                </Link>
              </li>
              <li className={`navbar-item ${getPath('/contact')}`}>
                <Link to="/contact" onClick={() => setToggle(false)}>
                  Contact
                </Link>
              </li>
              <li className={`navbar-item ${getPath('/blog')}`}>
                <Link to="/blog" onClick={() => setToggle(false)}>
                  Blog
                </Link>
              </li>
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
