import React from 'react';
import './ImageSection.scss';
 import AnimatedImageSection from '../../Animation/AnimatedImageSection';

import { images } from '../../../constants';

const ImageSection = () => {
  return (
    <AnimatedImageSection>
    <div className="imagecontainer">
            <img
          src={images.teamWork1}
          alt="Team working"
          className="image"
        />

    </div>
    </AnimatedImageSection>
  );
};

export default ImageSection;