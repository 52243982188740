import React from "react";
import "./DescriptionSection.scss";
import AnimatedSection from "../../Animation/AnimatedSection";

const DescriptionSection = ({ data }) => {
  return (
    <AnimatedSection>
    <section className="description-section">
      <div className="description-section__side-title">{data.whoweare}</div>
      <div className="description-section__content">
        <p>
        {data.whowearetxt}
        </p>
      </div>
    </section>
    </AnimatedSection>
  );
};

export default DescriptionSection;
