import React from 'react';
import './OurProcess.scss';
import { motion } from 'framer-motion';

const OurProcess = ({ data }) => {
    const processCard = data?.process_section || [];

    return (
        <div className="our-process-container">
            <div className="header">
                <p>{data?.processtitle1} <br /> {data?.processtitle2}</p>
            </div>
            <div className="process-list">
                {processCard.map((process, index) => (
                    <motion.div
                        key={index}
                        className="process-card"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 1, delay: index * 0.3, ease: "easeInOut" }}
                    >
                        <div className='user'>
                            <div className="process-info">
                                <h3>{process.processtitle}</h3>
                            </div>
                        </div>
                        <p>{process.processtext}</p>
                        <div className='line-break'></div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
}

export default OurProcess;
