import React from 'react';
import './InfoContactSection.scss'; 

const InfoContactSection = ({ data }) => {
  if (!data.imageGallery || data.imageGallery.length < 5) {
    return <div>Loading...</div>; // Or handle the error as needed
  }

  const office = data.imageGallery[0].asset.url;
  const call = data.imageGallery[1].asset.url;
  const linkedin = data.imageGallery[2].asset.url;
  const mail = data.imageGallery[3].asset.url;
  const twitter = data.imageGallery[4].asset.url;

  return (
    <div className="contactContainer">
      <div className='firstsec'>
        <h3>{data.header2}</h3>
        <p>{data.subheader2}</p>
      </div>
      <div className='row'>
        <div className="contact-item">
          <img src={mail} alt="Email icon" className="icon" />
          <div>
            <h3>{data.email}</h3>
            <p>{data.emailaddress}</p>
          </div>
        </div>
        <div className="contact-item">
          <img src={call} alt="Phone icon" className="icon" />
          <div>
            <h3>{data.phone}</h3>
            <p>{data.phonenum}</p>
          </div>
        </div>
      </div>
      <div className="contact-item">
        <img src={office} alt="Office icon" className="icon" />
        <div>
          <h3>{data.office}</h3>
          <p>{data.officeloc}</p>
        </div>
      </div>
      <h3>{data.social}</h3>
      <div className="social-media">
        <img src={linkedin} alt="LinkedIn icon" className="icon" />
        <img src={twitter} alt="Twitter icon" className="icon" />
      </div>
    </div>
  );
};

export default InfoContactSection;