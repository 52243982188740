import React from 'react';
import './ProductModal.scss';

const ProductModal = ({ product, onClose, modalDes , data  }) => {
    if (!product) return null;

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>X</button>
                <h2>{product.title}</h2>
                <p>{product.innerdescription}</p>
                <div className="image-container">
                    <img src={product.image.asset.url} alt={product.name} />
                </div>
                <p>{modalDes}</p>
            </div>
        </div>
    );
};

export default ProductModal;
