import React from 'react';
import './ServicesSection.scss';
import { images } from '../../../constants';

import { useNavigate } from 'react-router-dom'; 

const ServicesSection = ({ data }) => {
    const navigate = useNavigate(); 


    const handleButtonClick = () => {
        navigate('/services');
    };

    const handleWorkWithUs = () => {
        navigate('/contact')
    }


    
    const services =  data.service_section;
  

    return (
        <section className="services-section">
            <div className="services-grid">
                {services.map((service, index) => (
                    <div
                        className="service-card"
                        key={index}
                        style={{
                            '--card-color': '#0078D4',
                            '--hover-color': '#0078D4'
                        }}
                    >
                        <img src={service.card_icon.asset.url} alt={`${service.card_title} icon`} className="service-icon" />
                        <h3 className="service-title">{service.card_title}</h3>
                        <p className="service-description">{service.card_text}</p>
                        <div className="service-link" onClick={handleButtonClick}>
                            <span >→</span>
                        </div>
                    </div>
                ))}
            </div>
            <button className="cta-button" onClick={handleWorkWithUs}>{data.workbtn}</button>
        </section>
    );
}

export default ServicesSection;
