import React from "react";
import "./HeroSection.scss";
import AnimatedImageSection from "../../Animation/AnimatedImageSection";
import AnimatedSection from "../../Animation/AnimatedSection";

const HeroSection = ({data}) => {
  const teamWork1 = data.keyPeople[0].imgUrl.asset.url;
  return (

    <section className="hero-container">
    
      <div className="parent">
        <AnimatedSection>
        <div className="about_content">
          <h1>
           {data.title}
          </h1>
          <div className="flex">
            <div></div>
            <p>
           {data.subtitle}
            </p>
          </div>
        </div>
        </AnimatedSection>
        <AnimatedImageSection>
        <img
          src={teamWork1}
          alt="Team working"
          className="image"
        />
        </AnimatedImageSection>
      </div>
    </section>
  );
};

export default HeroSection;
