import React from 'react';
import './Footer.scss';
import { images } from '../../constants';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  const getPath = (path) => location.pathname === path ? 'active' : '';

  return (
    <footer className='app__footer'>
      <div className='app__footer-logo'>
        <img src={images.logo} alt="logo" />
      </div>
      <ul className='app__footer-links'>
        <li className={`footer-item ${getPath('/')}`}>
          <Link to="/">Home</Link>
        </li>
        <li className={`footer-item ${getPath('/about-us')}`}>
          <Link to="/about-us">About Us</Link>
        </li>
        <li className={`footer-item ${getPath('/services')}`}>
          <Link to="/services">Services</Link>
        </li>
        <li className={`footer-item ${getPath('/product')}`}>
          <Link to="/product">Product</Link>
        </li>
        <li className={`footer-item ${getPath('/contact')}`}>
          <Link to="/contact">Contact</Link>
        </li>
        <li className={`footer-item ${getPath('/blog')}`}>
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
